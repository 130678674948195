.headings{
    border: 1.7px solid #aa55ff;
    color: #aa55ff;
    font-weight: bold;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    width: fit-content;
}

.product-card {
    border: 2.5px solid #aa55ff;
    height: 100%;
}

.product-img-wrapper {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.product-img-wrapper img {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.btn-buy-now {
    width: 100%;
    background-color: #aa55ff;
    color: white;
    font-weight: bolder;
}

.btn-category {
    background-color: #aa55ff;
    color: white;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    border-radius: 50px;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animated-text {
    animation: fade 2s infinite;
    text-align: center;
    color: #aa55ff;
    font-size: 50px;
    font-weight: bold;
}